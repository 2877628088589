.deck {
    max-width: 800px;
    display: flex;
    margin: auto;
    justify-content: center;
    flex-wrap: wrap;
}

.holderMain {
    width: 100%;
    min-height: 20px;
}

.card {
    height: 150px;
    width: 150px;
    background-color: rgb(238, 238, 238);
    border-radius: 20px;
    margin: 7px;
    padding: 7px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card:hover {
    background-color: rgb(230, 230, 230);
}

.mainCard {
    height: auto;
    width: 90%;
    max-width: 500px;
    flex-direction: row;
    margin: auto;
}

.header {
    flex-grow: 1;
    min-width: 0;
    position: relative;
}

.header,
.header h2 {
    color: black;
    text-decoration: none;
    font-weight: 400;
    border: 0;
    font-size: 16px;

}

.controles {
    height: 20px;
    display: flex;
    align-items: center;
    cursor: default;
    padding: 15px 0 5px 0;
    justify-content: space-between;
    align-items: center;
}
.mainCard > .controles{
    margin-left: 40px;
    width: 30%;
    flex-grow: 1;
    min-width: 100px;
    max-width: 150px;
}
/* .mainCard .reload{
    margin-left: 10svw;
} */

.controles>a {
    height: 20px;
    cursor: pointer;
    display: flex;
    position: relative;
}
.tooltip{
    position: absolute;
    top: calc(100% + 10px);
    left: 4px;
    background-color: white;
    padding: 4px;
    display: none;
    width: 70px;
    background-color: rgb(57, 57, 57);
    font-size: 10px;
    color: white !important;
    z-index: 1;
}
.tooltip::before{
    position: absolute;
    height: 10px;
    width: 10px;
    top: -5.5px;
    left: 5px;
    background-color: inherit;
    content: '';
    transform: rotate(45deg);
}
*:hover>.tooltip{
    display: block;    
}

.controles>a>* {
    margin: 0 3px;
    color: rgb(0, 0, 115);
}

.docIcon {
    height: 100%;
}

.reload {
    border-radius: 10px;
}
.reload>img{
    opacity: 0.5;
    transition: transform 0.3s;
}

.reload>img:hover {
    transform: rotate(0.4rad);
}

.lang{
    color: red !important;
    text-decoration: none;
}