@keyframes typing {

    0%,
    60%,
    100% {
      transform: scale(1);
    }
  
    30% {
      transform: scale(1.5);
    }
  }
  .dot {
    content: '.';
    font-size: 20px;
    margin: 2px;
    display: inline-block;
    animation: typing 1.5s infinite ease-in-out;
    line-height: 10px;
  }
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }