.chat {
  width: 10%;
  min-width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.chatHeader {
  background-color: #68dce4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatHeader>h1{
  margin: 0;
  padding: 8px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 100;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 20px;

}
.muteButton{
  height: 30px;
  width: 30px;
  border-radius: 5px;
  position: relative;
  margin: 0 0 0 1px;
  cursor: pointer;
  user-select: none;
  background-color: #68dce4;
  flex-grow: 0;
}
.muteButton>input{
  visibility: hidden;
}
.muteButton>img{
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
  transition: transform .4s;
  filter: invert();
}
.textIcon{
  transform: scale(0);
}
.muteButton:has(input:checked) > .soundIcon{
  transform: scale(0);
}
.muteButton:has(input:checked) > .textIcon{
  transform: scale(1);
}

.chatBody {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 0;
  margin: 0;
}
.messagesContainer{
  padding-right: 10px;
  overflow: clip;  
}

.footer{
  display: flex;
  align-items: center;
  padding: 10px;
  min-height: 0;
  pointer-events: all;
}

.chatInput {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  border: 2px solid black;
  border-radius: 10px;
  overflow: hidden;
  height: 30px;
  background-color: white;
}

.chatInput>*{
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.mainInputArea{
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
  min-width: 0;
}
.waveform{
  border: 1px solid black;
  min-width: 100px;
  max-width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}
.waveform>*{
  min-width: 10px;
  border-radius: 5px;
  background-color: blue;
}
.chatInput textarea {
  box-sizing: border-box;
  border: none;
  outline: none;
  resize: none;
  padding: 10px;
  padding-right: 0;
  height: 100%;
  font-size: 11px;
  line-height: 10px;
  width: 100%;
  
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatInput>button {
  justify-content: center;
  padding: 5px;
  border: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.triangle, .audioIcon{
  transition: transform .4s;
}
.triangle {
  height: 15px;
  width: 15px;
  background-color: #68dce4;
  position: relative;
  overflow: hidden;
  transform: scale(0);
}
.sendButton .triangle{
  transform: scale(1);
}

.triangle::before,
.triangle::after {
  content: '';
  background-color: white;
  height: 100%;
  width: 200%;
  position: absolute;
  left: 10%;
}

.triangle::before {
  bottom: 45%;
  transform: rotate(30deg);
}

.triangle::after {
  top: 45%;
  transform: rotate(-30deg);
}

.audioIcon{
  position: absolute;
  top: 9px;  
  left: 4px;
  height: 50%;
  filter: invert(16%) sepia(18%) saturate(1145%) hue-rotate(132deg) brightness(96%) contrast(85%);
}
.sendButton .audioIcon{
  transform: scale(0);
}

.chatInput>button::before {
  transform: rotate(45deg);
  top: -30px;
}

.chatInput>button::after {
  transform: rotate(-45deg);
  bottom: -30px;
}

.chatMessageContainer {
  display: flex;
  margin-bottom: 10px;
  transition: opacity 0.3s;
  font-size: 14px;
}
.chatMessageContainer:hover{
  opacity: 100%;
}

.chatMessageContainer>div {
  border-radius: 10px;
  padding: 10px 15px;
  overflow: clip;
  max-width: 70%;
}

.userMessage {
  justify-content: left;
  pointer-events: all;
}

.userMessage>div {
  background-color: #57d3dc;
}

.botMessage {
  pointer-events: all;
  justify-content: right;
}

.botMessage>div,
.typingIndicator>div {
  background-color: #f0ecec;
}
.old{
  opacity: 30%;
}
.older{
  opacity: 10%;
}
.oldest{
  opacity: 1%;
}
.md > *{
  margin: 0;
}
@media only screen and (max-width: 700px) {
  .chat {
    width: 100%;
    height: calc(100% - 56vw);
  }
  .old, .older, .oldest{
    opacity: 100%;
  }
  .messagesContainer{
    overflow: clip auto;
    max-height: 100%;
  }
}