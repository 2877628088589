.main {
    flex-grow: 1;
    height: 100vh;
    position: relative;
    /* display: flex; */
    flex-direction: column;
    overflow: hidden;
}

.slides {
    flex-grow: 1;
    border: none;
    position: relative;
    aspect-ratio: 16 / 9;
    max-height: 100%;
    max-width: 100%;
}
.slides > *{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
}

.controls {
    position: absolute;
    top: min(calc(100vh - 42px), 57vw);
    left: 10px;
    width: 110px;
    border-radius: 20px;
    background-color: rgb(230, 230, 230);
    display: flex;
    padding: 0 6px;
    z-index: 10;
}

@media only screen and (max-width: 700px) {
    .controls {
      position: relative;
      top: auto;
      border: 1px solid black;
    }
  }

.controls>*{
    background-color: transparent;
    border-radius: 13px;
    height: 26px;
    border: none;
    margin: 4px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.controls>button:not(.disabled):hover{
    background-color: white;
}
.controls>button{
    width: 26px;
}
.arrow::after{
    content: '';
    display: block;
    height: 7px;
    width: 7px;
    border: solid rgb(66, 66, 66);
    border-width: 0 0 2.34px 2.34px;
}
.arrow.disabled::after{
    border-width: 0;
}

.leftArrow::after{
    transform: translate(2px, 0) rotate(45deg);
}
.rightArrow::after{
    transform: translate(-2px, 0)  rotate(225deg);
}
.slideIndicator{
    background-color: white;
    min-width: 35px;
    text-align: center;
    outline: none;
    font-weight: 500;
    font-size: 15px;
    -moz-appearance: textfield;
    appearance: textfield;
}

.disabled{
    pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
.slideIndicator::-webkit-outer-spin-button,
.slideIndicator::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
