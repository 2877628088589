
.main{
    position: relative;
    display: flex;
}
.canvas, .fillBar{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
}
.range{
    width: 100%;
    --bck-color: white;
}
.range, .fillBar, .background {
    height: 24px;
    margin: 0;
}
.fillBar{
    pointer-events: none;
}

.range, .range.webkit-track {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--bck-color);
}

.range::-moz-range-track {
    height: 24px;
}

.range::-ms-fill-upper {
    background-color: blue;
    border: solid 2px #444;
    border-radius: 8px;
}

.range::-ms-track {
    border: none;
    color: transparent;
}

.range::-moz-range-thumb {
    border-radius: 0;
    height: 24px;
    width: 3px;
    border: none;
    background-color: transparent;
}

.range::-ms-thumb {
    width: 3px;
    height: 24px;
}

.range::-webkit-slider-thumb {
    border-radius: 0;
    height: 24px;
    width: 3px;
    -webkit-appearance: none;
}