body, html, #root {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.indexPage {
  display: flex;
  height: 100%;
  background-color: black;
}
@media only screen and (max-width: 500px) {
  .indexPage {
    flex-direction: column;
  }
}

img.theme-color{
  filter: invert(16%) sepia(18%) saturate(1145%) hue-rotate(132deg) brightness(96%) contrast(85%);
}