.main {
    display: flex;
    align-items: center;
}

.playButton {
    border: none;
    background-color: transparent;
    height: 18px;
    padding: 0;
    margin-right: 10px;
    cursor: pointer;
}

.playIcon {
    height: 100%;
}
.pauseIcon{
    height: 100%;
    width: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.pauseIcon::before, .pauseIcon::after{
    content: '';
    height: 80%;
    width: 30%;
    background-color: white;
}
.audio{
    visibility: hidden;
}

.display{
    position: relative;
}
.range, .fillBar {
    height: 30px;
    margin: 0;
}
.fillBar{
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.range, .range.webkit-track {
    -webkit-appearance: none;
    appearance: none;
}

.range::-moz-range-track {
    height: 30px;
}

.range::-ms-fill-upper {
    background-color: blue;
    border: solid 2px #444;
    border-radius: 8px;
}

.range::-ms-track {
    border: none;
    color: transparent;
}

.range::-moz-range-thumb {
    border-radius: 0;
    height: 30px;
    width: 16px;
    border: none;
    background-color: transparent;
}

.range::-ms-thumb {
    width: 12px;
    height: 30px;
}

.range::-webkit-slider-thumb {
    border-radius: 0;
    height: 30px;
    width: 20px;
    -webkit-appearance: none;
}